import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { BillingService } from '../../../services/billing.service';
import { AppService } from '../../../services/app.service';
import { BillingInterface, PlanInterface, UserInterface } from 'voxr-shared';
import { UserService } from '../../../services/user.service';
import { PlanCardComponent } from '../plan-card/plan-card.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatCardModule } from '@angular/material/card';

@Component({
  selector: 'app-setup-billing',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    MatCardModule,
    PlanCardComponent
  ],
  templateUrl: './setup-billing.component.html',
  styleUrl: './setup-billing.component.scss'
})
export class SetupBillingComponent {
  private userService = inject(UserService);
  private billingService = inject(BillingService);
  private app = inject(AppService);

  loading = false;
  user?: UserInterface;
  billing?: BillingInterface;
  plans?: PlanInterface[];

  constructor() {
    this.userService.user$.pipe(takeUntilDestroyed()).subscribe(async user => { if (user) await this.load(user) });
  }

  async load(user: UserInterface) {
    try {
      this.loading = true;
      this.user = user;
      this.plans = await this.billingService.plans();
      this.billing = user._metadata?.billing as BillingInterface;
    } catch (error: any) {
      this.app.message$.next({ severity: 'danger', summary: 'Error', detail: error.message });
    } finally {
      this.loading = false;
    }
  }

  settingBilling = false;
  async setupBillingAccount() {
    this.settingBilling = true;
    try {
      await this.billingService.checkout();
    } catch (error: any) {
      this.app.message$.next({ severity: 'danger', summary: 'Error', detail: error.message });
    } finally { this.settingBilling = false; }
  }

  async checkoutPlan(planId: string) {
    this.settingBilling = true;
    try {
      if (this.billing?.customerId)
        await this.billingService.subscribe(planId);
      else
        await this.billingService.checkout(planId);
    } catch (error: any) {
      this.app.message$.next({ severity: 'danger', summary: 'Error', detail: error.message });
    } finally { this.settingBilling = false; }
  }
}
