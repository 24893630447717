<div class="tw-container tw-flex tw-flex-col tw-gap-6" [ngClass]="{'tw-animate-pulse': loading}">
  @if(user) {
  @if(billing?.subscription?.status === 'active') {
  <h3 class="tw-self-center">Your subscription is active</h3>
  @if(billing?.planId) {
  <!-- <h6>Your active plan</h6> -->
  <app-plan-card [plan]="billing!.planId" class="tw-self-center"></app-plan-card>
  }
  } @else {
  @if(plans && plans.length) {
  <p class="tw-self-center">
    Choose a plan that suits your needs.
  </p>
  <div class="tw-self-center uw-card-grid tw-gap-4 "
    [ngStyle]="{'grid-template-columns': 'repeat(' + (plans.length > 4 ? 4 : plans.length) + ', minmax(0, 1fr))' }">
    @for(plan of plans; track $index) {
    <app-plan-card class="tw-max-w-96" [plan]="plan">
      <div footer>
        <div class="p-fluid">
          <p-button severity="primary" label="Buy now" icon="pi pi-credit-card"
            (click)="checkoutPlan(plan._id!.toString())" [loading]="settingBilling"></p-button>
        </div>
      </div>
    </app-plan-card>
    }
  </div>
  }

  @if(!billing?.customerId) {
  <mat-card class="tw-self-center">
    <mat-card-content>
      <div class="tw-flex tw-flex-col tw-gap-4">
        <p class="tw-self-center">
          @if(plans?.length){Or }@else {Please} set up your payment method so we can assist you in selecting the best
          plan
          tailored to your needs.
        </p>
        <div class="tw-flex tw-justify-center">
          <p-button severity="warning" label="Setup Payment Method" icon="pi pi-credit-card"
            (click)="setupBillingAccount()" [loading]="settingBilling"></p-button>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
  } @else {
  <mat-card class="tw-max-w-sm tw-self-center">
    <mat-card-content>
      Your payment method is set up. We will contact you for a custom plan @if(plans?.length){if none of the above suits
      your needs}.
    </mat-card-content>
  </mat-card>
  }
  }

  }









</div>